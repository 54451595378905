import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

/*
* Loads Marketo Form then hides user ID paramater and enables sanitization
* Need to view or edit the form? Replace the four # with the ID to open form in marketo
* https://app-sj19.marketo.com/m#FOP####
*   "form": {
      "marketoForm": {
        "id": ####
      },
*/

function MarketoForm(props) {
  const { data } = props;
  const { id } = data;
  const MARKETO_ID = `mktoForm_${id}`;
  useEffect(() => {
    function disinfect(userInput) {
      return DOMPurify.sanitize(userInput);
    }

    // if pmt.traceId has utms, update Marketo form fields
    function setUtms() {
      const pmtTraceId = window.pmt('traceId');
      const pmtTraceIdUtms = pmtTraceId.split('|')[1];
      if (pmtTraceId && pmtTraceId.indexOf('utm') !== -1) {
        const mForm = document.getElementById(MARKETO_ID);

        [...mForm.elements].forEach((elem) => {
          if (elem.name.indexOf('utm') !== -1) {
            const utmType = elem.name.split('utm').pop().split(/[=,|]/).shift();
            const utmParamName = `utm_${utmType}`;
            const utmValue = pmtTraceIdUtms
              .split(`${utmParamName}=`)
              .pop()
              .split('.')
              .shift();

            if (pmtTraceIdUtms.match(utmParamName)) {
              /* eslint-disable no-param-reassign */
              elem.value = utmValue;
              /* eslint-enable */

              window.pmt('log', [
                {
                  [`[${utmParamName}] ${elem.name}: ${elem.value}`]: elem
                }
              ]);
            }
          }
        });
      }
    }

    // invoked in setSanitizer() / handles sanitization to create clean form values
    function submitSanitizedForm() {
      const mktoForm = document.querySelectorAll('input.mktoField');
      if (mktoForm.length > 0) {
        const values = Array.from(mktoForm);
        const sanitizedValues = values.map((field) => disinfect(field.value));
        for (let i = 0; i < mktoForm.length; i += 1) {
          mktoForm[i].value = sanitizedValues[i];
        }
        const toggleSubmit =
          document.querySelector('button.mktoButton') || null;
        setUtms();
        toggleSubmit.click(this);
      }
    }

    // invoked in updateForm() / sets click event to sanitize data when form is submitted
    function setSanitizer() {
      const marketoSubmitButton =
        document.querySelector('button.mktoButton') || null;
      if (marketoSubmitButton) {
        marketoSubmitButton.addEventListener('click', () => {
          submitSanitizedForm();
        });
      }
    }
    // Update and load Marketo form with API refs: https://developers.marketo.com/javascript-api/forms/api-reference/
    function updateForm() {
      window.MktoForms2.loadForm('//pages.getpostman.com', '067-UMD-991', id);
      window.MktoForms2.whenReady((form) => {
        setTimeout(() => {
          setUtms();
        }, 1000);

        setSanitizer();
        form.onSuccess((_values, followUpUrl) => {
          if (!followUpUrl.match(/http/) && followUpUrl.match(/\.pdf/)) {
            const followUpUrlRootRelative = followUpUrl.replace(
              /^https:\/\/[a-z.]+\.com\//,
              '/'
            );
            const ASSET_PREFIX_KEY = '_mk-www';
            const followUpUrlRootRelativeAsset =
              followUpUrlRootRelative.replace(`${ASSET_PREFIX_KEY}/`, '');
            const followUpUrlAsset = followUpUrlRootRelativeAsset
              .split('/')
              .pop();
            const followUpUrlRootRelativeAssetPrefixed = `${window.pmt(
              'prefix'
            )}${followUpUrlAsset}`;

            document.location.href = `${followUpUrlRootRelativeAssetPrefixed}`;

            return false;
          } if (id === 2441) {
            return false;
          }

          return true;
        });
      });
    }
    function loadForm() {
      if (id !== 'duplicate') {
        updateForm();
      }
      return null;
    }
    (function useForm() {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = '//pages.getpostman.com/js/forms2/js/forms2.min.js';
      s.onreadystatechange = () => {
        if (s.readyState === 'complete' || s.readyState === 'loaded') {
          loadForm();
        }
      };
      s.onload = loadForm;
      document.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, [id, MARKETO_ID]);

  return (
    <>
      <form id={MARKETO_ID} className="mk_form" aria-hidden="true" />
      <style>{` #${MARKETO_ID} {display: none !important;} `}</style>
    </>
  );
}
// For testing: comment <style> tags above to view marketo form

MarketoForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default MarketoForm;
